import axios, { AxiosRequestConfig, Method, AxiosResponse } from 'axios';
import qs from 'qs';
import { EMessage } from '@aciga/e-ui-next';
import { env, EnvHostKey } from './config';

/**
 * 请求正常服务端返回 code 处理
 * @param {*} res
 */
const checkCode = (res: AxiosResponse) => {
  if (res.status === 200) {
    if (res.data && !res.data?.code) {
      return res;
    }
    // 退出接口特殊处理
    if (res.statusText === 'OK') {
      return res;
    }
    return Promise.reject(res);
  }
  return Promise.reject(res);
};

/**
 * 请求异常 status 处理
 * @param {*} res
 */
const checkStatus = (error: { message: string }) => {
  let { message } = error;
  // 401 且不是 开发环境 则跳转

  const path = window.location.pathname;
  if (message.includes('401') && !path.startsWith('/login')) {
    window.open(
      `${window.location.origin}/login?redirectUrl=${encodeURIComponent(window.location.href)}`,
      '_self'
    );
  }
  if (message.includes('401')) {
    message = '您的授权已过期或未授权，请授权后访问。';
  } else if (message === 'Network Error') {
    message = '后端接口连接异常';
  } else if (message.includes('timeout')) {
    message = '后端接口请求超时';
  } else if (message.includes('Request failed with status code')) {
    const code = message.substr(message.length - 3);
    message = `后端接口${code}异常`;
  }
  EMessage({
    type: 'error',
    message
  });

  return Promise.reject(error);
};

interface IRequestConfig extends AxiosRequestConfig {
  otherConfig?: any;
}

/**
 * 兼容 contentType 类型
 * @param {*} config
 */
const checkContentType = (config: IRequestConfig) => {
  if (
    Object.prototype.toString.call(config.data) !== '[object FormData]' &&
    config.headers['Content-Type'] === 'application/x-www-form-urlencoded;charset=UTF-8'
  ) {
    config.data = qs.stringify(config.data);
  }
  if (config.otherConfig) {
    config = { ...config, ...config.otherConfig };
  }
  // console.log('上传接口,', config);
  return config;
};

const instance = axios.create({
  baseURL: env.apiBase,
  timeout: 10 * 1000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  }
});

const ossAxiosInstance = axios.create({});

// 请求拦截处理
instance.interceptors.request.use(
  (config) => {
    // @ts-ignore
    if (config.headers.type === 'upload') {
      config.baseURL = env.fileBase;
      delete config.headers.type;
    }
    return checkContentType(config);
  },
  (error) => Promise.reject(error)
);

// 响应拦截处理
instance.interceptors.response.use(
  (response) => checkCode(response),
  (error) => checkStatus(error)
);

type HandRes<T> = {
  code: number;
  message: string;
  data: T;
};

function Request<T = unknown>(configParam: AxiosRequestConfig): Promise<HandRes<T>> {
  // const startTime = Date.now();
  return new Promise((resolve, reject) => {
    instance
      .request<T, AxiosResponse<HandRes<T>>>({ ...configParam, ...configParam.params })
      .then((res) => {
        resolve(res.data);
        // console.log(configParam.url, Date.now() - startTime);
      })
      .catch((res) => {
        console.log('error, 网络异常');
        reject(res.message);
      });
  });
}

export default {
  // 抛出axios 实例方便扩展
  request: instance,
  /**
   * GET 请求
   * @param {*} url
   * @param {*} params
   * @param {*} headers
   */
  get<T>(
    url: string,
    params: object,
    headers = {},
    otherBase: 'apiBase' | 'logoutBase' = 'apiBase'
  ) {
    if (otherBase !== 'apiBase') {
      return Request<T>({
        baseURL: env[otherBase],
        url,
        method: 'GET',
        params,
        headers
      });
    }
    return Request<T>({
      url,
      method: 'GET',
      params,
      headers
    });
  },

  /**
   * POST请求
   * @param {*} url
   * @param {*} data
   * @param {*} headers
   * @param {*} params
   */
  post<T>(url: string, data: object, headers = {}, params = {}) {
    return Request<T>({
      url,
      method: 'POST',
      data,
      headers,
      params
    });
  },

  postV2<T>(param: {
    url: string;
    data: object;
    headers?: {};
    params?: {};
    otherBase?: EnvHostKey;
  }) {
    return Request<T>({
      method: 'POST',
      baseURL: env[param.otherBase ?? 'apiBase'],
      url: param.url,
      data: param.data,
      headers: param.headers,
      params: param.params
    });
  },

  /**
   * 上传请求
   * fixme: 上传下载和接口应该用不同的instance
   * @param {*} url
   * @param {*} formdata
   * @param {*} headers
   */
  upload(
    url: string,
    formdata: object,
    headers = {
      'Content-Type': 'multipart/form-data'
    }
  ) {
    return instance({
      url: env.apiBase + url,
      method: 'POST',
      headers,
      data: formdata
    });
  },

  /**
   * 下载请求
   * fixme: 上传下载和接口应该用不同的instance
   * @param {*} url
   * @param {*} data
   * @param {*} headers
   * @param {*} method
   */
  download(url: string, data: object, headers?: any, method?: Method) {
    return instance({
      url,
      method,
      responseType: 'blob',
      transformResponse: [(res) => res],
      data,
      headers
    });
  },

  // 上传oss
  uploadOss(param: { url: string; header?: { string: string }; formData: object }) {
    // param.header['Content-Type'] = 'application/x-www-form-urlencoded';
    return ossAxiosInstance({
      url: param.url,
      data: param.formData,
      headers: {
        ...param.header
        // access-control-allow-origin
        // 'Access-Control-Allow-Origin': 'http://localhost',
        // 'Access-Control-Allow-Methods': 'PUT,POST,GET'
        // 'Access-Control-Allow-Origin': '*'
      },
      method: 'POST'
    });
  }
};
