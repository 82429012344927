// 商品类目模块路由
import { RouteRecordRaw } from 'vue-router';
// import { AppEnv } from '@/utils';

const LayoutView = () => import('@/components/layout/Index.vue');
const login = () => import('@/pages/login/index.vue');
const systemList = () => import('@/pages/systemList/index.vue');

const systemsRoute: RouteRecordRaw[] = [
  {
    path: '/login',
    component: login,
    meta: {
      title: '登录',
      icon: 'content'
    }
  },
  {
    path: '/systems',
    component: LayoutView,
    meta: {
      title: '',
      icon: 'content'
    },
    children: [
      {
        path: '',
        component: systemList,
        meta: {
          desc: '系统列表',
          title: '系统列表'
        }
      }
    ]
  }
];

export { systemsRoute };
