import api from './request';

export function getUserEmployeeInfo() {
  return api.postV2<any>({
    url: '/auth-admin/auth/getMyAuth',
    data: { projectId: 1 },
    params: {
      apiTenant: 6
    }
  });
}
