// 将异步组件写在常量中，便于语义化理解
// modules文件存放模块路由，所有模块需在该文件夹新建对应模块路由文件。如demo1模块的创建及引用方式。
// 无用代码需手动删除：如demo2和demo3路由
// import { orgManagementModules } from '@/router/modules/organizingManagement';

import { systemsRoute } from '@/router/modules/systems';

const NoRightsPage = () => import('@/pages/noRightsPage/index.vue');

export const routes = [
  {
    path: '/',
    redirect: '/systems',
    meta: {
      hidden: true
    }
  },
  ...systemsRoute,
  {
    path: '/:w+',
    name: 'power',
    component: NoRightsPage,
    meta: {
      title: '暂无权限',
      // hidden 表示无需在路由展示
      hidden: true
    }
  }
];
