import { createApp } from 'vue';

import EUI from '@aciga/e-ui-next';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import locale from 'element-plus/lib/locale/lang/zh-cn';
// @ts-ignore
import './style/global.scss';
import { createPinia } from 'pinia';
import '@aciga/e-ui-next/dist/theme-chalk/css/index.css';

import router from './router';
import App from './App.vue';

// 一个轻量的store管理工具
// https://pinia.vuejs.org/zh/introduction.html
const pinia = createPinia();

const app = createApp(App);

app.use(ElementPlus, { locale });
app.use(EUI, undefined);
app.use(pinia);
app.use(router);
app.mount('#app');
